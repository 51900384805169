<template>
  <div class="banner-container">
    <v-img
      src="../../../assets/images/home-cover.jpeg"
      alt="Descripción de la imagen"
      contain
    >
      <div class="overlay-gradient"></div>
      <div class="text-container">
        <div
          class="overlay"
          style="
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
            padding-left: 40px;
          "
        >
          <h2
            class="mon-bold"
            style="
              font-size: min(max(35px, 5vw), 60px);
              text-wrap: wrap;
              max-width: 1050px;
              color: #f5f5f5;
              margin-bottom: 45px;
              margin-right: 20%;
              margin-top: 10%;
            "
          >
            {{ texts.banner.titleSection }}
          </h2>
          <h3
            class="mon"
            style="
              max-width: 1050px;
              font-size: 22px;
              color: #f5f5f5;
              padding-left: 10px;
              margin-right: 20%;
            "
          >
            {{ texts.banner.subtitleSection }}
          </h3>
          <div
            style="
              display: flex;
              margin-top: 30px;
              flex-wrap: wrap;
              gap: 15px;
            "
          >
            <v-btn
              to="/dashboard"
              text
              class="button-register mon-bold"
              style="color: #f5f5f5 !important;"
            >
              {{ texts.banner.buttonBuyer }}
            </v-btn>
          </div>
        </div>
      </div>
    </v-img>
  </div>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      default: "",
    },
  },
  name: "HomeBanenrLayout",
  data() {
    return {
      //VARIABLES
      isResponsive: false
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
  },
};
</script>

<style scoped>

.video-container {
  position: relative;
  width: 100vw; /* 100% del ancho de la ventana */
  height: 100vh; /* 100% de la altura de la ventana */
  overflow: hidden; /* Para asegurar que el video no desborde el div */
}

video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Para ajustar el video al tamaño del div sin distorsión */
}

.button-register {
  width: 260px !important;
  height: 59px !important;
  background-color: #0075B8;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  letter-spacing: 0px;
  color: #F5F5F5 !important;
  font-size: 16px !important;
  text-transform: initial;
  padding: 20px 20px;
}
.fruits-image {
  position: absolute;
  width: 600px;
  right: -20px;
  top: 0px;
  overflow: hidden;
}

.txt-title {
  color: #ffffff;
  text-align: left;
  letter-spacing: 0px;
  text-shadow: 0px 3px 6px #00000095;
  opacity: 1;
  font-size: 30px;
}

.txt-description {
  color: #ffffff;
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  text-shadow: 0px 3px 6px #00000095;
  opacity: 1;
  font-size: 14px;
}

.overlay {
  position: relative;
}

.overlay-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(13, 38, 72, 0.9) 20%, /* Azul oscuro hacia la izquierda */
    rgba(13, 38, 72, 0.6) 40%, /* Azul medio hacia el centro */
    rgba(0, 0, 0, 0.22) 70% /* Negro hacia la derecha */
  );
  z-index: 1;
}

.text-container {
  position: relative;
  z-index: 2;
  padding-left: 40px;
  color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  width: 100%;
}

.banner-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}



@media (max-width: 960px) {
  .hidden-from-tablet {
    display: none;
  }

  .fruits-image {
    left: 50%;
    transform: translate(-50%, 0%);
    width: 350px;
  }

  .txt-title {
    font-size: 25px;
  }
}

@media (max-width: 600px) {
  .txt-description {
    font-size: 12px;
  }
}
</style>

<style>
.bg-welcome-proplat {
  background: url("../../../assets/images/bg-banner-welcome.png");
  width: 100%;
  opacity: 1;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 25px;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
}

@media (max-width: 960px) {
  .bg-welcome-proplat {
    padding: 15px;
  }
}
</style>